/*
 *
 * Appointment constants
 *
 */

export const FETCH_LOCATIONS = 'app/Appointment/FETCH_LOCATIONS';
export const FETCH_LOCATIONS_SUCCESS =
  'app/Appointment/FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAILURE =
  'app/Appointment/FETCH_LOCATIONS_FAILURE';

export const SET_SELECTED_LOCATION = 'app/Appointment/SET_SELECTED_LOCATION';
export const CLEAR_SELECTED_LOCATION =
  'app/Appointment/CLEAR_SELECTED_LOCATION';

export const FETCH_LOCATION_SERVICES =
  'app/Appointment/FETCH_LOCATION_SERVICES';
export const FETCH_LOCATION_SERVICES_SUCCESS =
  'app/Appointment/FETCH_LOCATION_SERVICES_SUCCESS';
export const FETCH_LOCATION_SERVICES_FAILURE =
  'app/Appointment/FETCH_LOCATION_SERVICES_FAILURE';

export const FETCH_LOCATION_MORE_INFO =
  'app/Appointment/FETCH_LOCATION_MORE_INFO';
export const FETCH_LOCATION_MORE_INFO_SUCCESS =
  'app/Appointment/FETCH_LOCATION_MORE_INFO_SUCCESS';
export const FETCH_LOCATION_MORE_INFO_FAILURE =
  'app/Appointment/FETCH_LOCATION_MORE_INFO_FAILURE';

export const FETCH_THERAPISTS = 'app/Appointment/FETCH_THERAPISTS';
export const FETCH_THERAPISTS_SUCCESS =
  'app/Appointment/FETCH_THERAPISTS_SUCCESS';
export const FETCH_THERAPISTS_FAILURE =
  'app/Appointment/FETCH_THERAPISTS_FAILURE';

export const SET_SELECTED_SERVICE = 'app/Appointment/SET_SELECTED_SERVICE';
export const CLEAR_SELECTED_SERVICE = 'app/Appointment/CLEAR_SELECTED_SERVICE';

export const FETCH_MASSAGE_TYPES = 'app/Appointment/FETCH_MASSAGE_TYPES';
export const FETCH_MASSAGE_TYPES_SUCCESS =
  'app/Appointment/FETCH_MASSAGE_TYPES_SUCCESS';
export const FETCH_MASSAGE_TYPES_FAILURE =
  'app/Appointment/FETCH_MASSAGE_TYPES_FAILURE';

export const SET_SELECTED_MASSAGE_TYPE =
  'app/Appointment/SET_SELECTED_MASSAGE_TYPE';
export const CLEAR_SELECTED_MASSAGE_TYPE =
  'app/Appointment/CLEAR_SELECTED_MASSAGE_TYPE';

export const SET_NOT_AVAILABILE_TILL_DATE =
  'app/Appointment/SET_NOT_AVAILABILE_TILL_DATE';
export const FETCH_AVAILABLE_THERAPISTS =
  'app/Appointment/FETCH_AVAILABLE_THERAPISTS';
export const FETCH_AVAILABLE_THERAPISTS_SUCCESS =
  'app/Appointment/FETCH_AVAILABLE_THERAPISTS_SUCCESS';
export const FETCH_AVAILABLE_THERAPISTS_FAILURE =
  'app/Appointment/FETCH_AVAILABLE_THERAPISTS_FAILURE';

export const SET_SELECTED_APPOINTMENT_SLOT =
  'app/Appointment/SET_SELECTED_APPOINTMENT_SLOT';
export const CLEAR_SELECTED_APPOINTMENT_SLOT =
  'app/Appointment/CLEAR_SELECTED_APPOINTMENT_SLOT';

export const FETCH_USER_PRESSURE = 'app/Appointment/FETCH_USER_PRESSURE';
export const FETCH_USER_PRESSURE_SUCCESS =
  'app/Appointment/FETCH_USER_PRESSURE_SUCCESS';
export const FETCH_USER_PRESSURE_FAILURE =
  'app/Appointment/FETCH_USER_PRESSURE_FAILURE';
export const FETCH_USER_PRESSURE_ERROR =
  'app/Appointment/FETCH_USER_PRESSURE_ERROR';

export const UPDATE_USER_PRESSURE = 'app/Appointment/UPDATE_USER_PRESSURE';
export const UPDATE_USER_PRESSURE_SUCCESS =
  'app/Appointment/UPDATE_USER_PRESSURE_SUCCESS';
export const UPDATE_USER_PRESSURE_FAILURE =
  'app/Appointment/UPDATE_USER_PRESSURE_FAILURE';

export const CLEAR_USER_CARDS = 'app/Appointment/CLEAR_USER_CARDS';

export const CHECK_GIFT_CODE = 'app/Appointment/CHECK_GIFT_CODE';
export const CHECK_GIFT_CODE_SUCCESS =
  'app/Appointment/CHECK_GIFT_CODE_SUCCESS';
export const CHECK_GIFT_CODE_FAILURE =
  'app/Appointment/CHECK_GIFT_CODE_FAILURE';
export const CLEAR_GIFT_CODE = 'app/Appointment/CLEAR_GIFT_CODE';

export const CREATE_USER_APPOINTMENT =
  'app/Appointment/CREATE_USER_APPOINTMENT';
export const CREATE_USER_APPOINTMENT_SUCCESS =
  'app/Appointment/CREATE_USER_APPOINTMENT_SUCCESS';
export const CREATE_USER_APPOINTMENT_FAILURE =
  'app/Appointment/CREATE_USER_APPOINTMENT_FAILURE';

export const SET_BOOKING_FLOW = 'app/Appointment/SET_BOOKING_FLOW';
export const SET_BUYING_REGULAR_MEMBERSHIP_FLOW =
  'app/Appointment/SET_BUYING_REGULAR_MEMBERSHIP_FLOW';

export const UPDATE_APPOINTMENT_TEXT =
  'app/Appointment/UPDATE_APPOINTMENT_TEXT';
export const UPDATE_APPOINTMENT_TEXT_SUCCESS =
  'app/Appointment/UPDATE_APPOINTMENT_TEXT_SUCCESS';
export const UPDATE_APPOINTMENT_TEXT_FAILURE =
  'app/Appointment/UPDATE_APPOINTMENT_TEXT_FAILURE';
export const UPDATE_APPOINTMENT_TEXT_ERROR =
  'app/Appointment/UPDATE_APPOINTMENT_TEXT_ERROR';

export const CLEAR_APPOINTMENT_BOOKING_DATA =
  'app/Appointments/CLEAR_APPOINTMENT_BOOKING_DATA';

export const FETCH_USER_COMPLETED_APPOINTMENTS =
  'app/Appointments/FETCH_USER_COMPLETED_APPOINTMENTS';
export const FETCH_USER_COMPLETED_APPOINTMENTS_SUCCESS =
  'app/Appointments/FETCH_USER_COMPLETED_APPOINTMENTS_SUCCESS';
export const FETCH_USER_COMPLETED_APPOINTMENTS_FAILURE =
  'app/Appointments/FETCH_USER_COMPLETED_APPOINTMENTS_FAILURE';

// My Appointments
export const FETCH_APPOINTMENTS = 'app/Appointments/FETCH_APPOINTMENTS';
export const FETCH_APPOINTMENTS_SUCCESS =
  'app/Appointments/FETCH_APPOINTMENTS_SUCCESS';
export const FETCH_APPOINTMENTS_FAILURE =
  'app/Appointments/FETCH_APPOINTMENTS_FAILURE';

// Status API
export const FETCH_STATUS = 'app/Appointments/FETCH_STATUS';
export const FETCH_STATUS_SUCCESS = 'app/Appointments/FETCH_STATUS_SUCCESS';
export const FETCH_STATUS_FAILURE = 'app/Appointments/FETCH_STATUS_FAILURE';

export const CANCEL_USER_APPOINTMENT =
  'app/Appointment/CANCEL_USER_APPOINTMENT';
export const CANCEL_USER_APPOINTMENT_SUCCESS =
  'app/Appointment/CANCEL_USER_APPOINTMENT_SUCCESS';
export const CANCEL_USER_APPOINTMENT_FAILURE =
  'app/Appointment/CANCEL_USER_APPOINTMENT_FAILURE';

export const SET_FILTER_STATE = 'app/Appointment/SET_FILTER_STATE';
export const CLEAR_FILTER_STATE = 'app/Appointment/CLEAR_FILTER_STATE';

export const SET_CURRENT_THERAPIST_CLICKED =
  'app/Appointment/SET_CURRENT_THERAPIST_CLICKED';
export const CLEAR_CURRENT_THERAPIST_CLICKED =
  'app/Appointment/CLEAR_CURRENT_THERAPIST_CLICKED';

export const SET_CURRENT_SELECTED_DATE =
  'app/Appointment/SET_CURRENT_SELECTED_DATE';
export const CLEAR_CURRENT_SELECTED_DATE =
  'app/Appointment/CLEAR_CURRENT_SELECTED_DATE';

export const SET_ENTERED_PHONE_NUMBER =
  'app/Appointment/SET_ENTERED_PHONE_NUMBER';
export const CLEAR_ENTERED_PHONE_NUMBER =
  'app/Appointment/CLEAR_ENTERED_PHONE_NUMBER';

export const SET_APPOINTMENT_NET_VALUE =
  'app/Appointment/SET_APPOINTMENT_NET_VALUE';
export const CLEAR_APPOINTMENT_NET_VALUE =
  'app/Appointment/CLEAR_APPOINTMENT_NET_VALUE';

export const SET_ENTERED_PROMO_CODE = 'app/Appointment/SET_ENTERED_PROMO_CODE';
export const CLEAR_ENTERED_PROMO_CODE =
  'app/Appointment/CLEAR_ENTERED_PROMO_CODE';

export const RETRY_APPOINTMENT_CHARGE =
  'app/Appointment/RETRY_APPOINTMENT_CHARGE';
export const RETRY_APPOINTMENT_CHARGE_SUCCESS =
  'app/Appointment/RETRY_APPOINTMENT_CHARGE_SUCCESS';
export const RETRY_APPOINTMENT_CHARGE_FAILURE =
  'app/Appointment/RETRY_APPOINTMENT_CHARGE_FAILURE';

export const RESERVE_APPOINTMENT_SAGA =
  'app/Appointment/RESERVE_APPOINTMENT_SAGA';
export const RESERVE_APPOINTMENT_SAGA_SUCCESS =
  'app/Appointment/RESERVE_APPOINTMENT_SAGA_SUCCESS';
export const RESERVE_APPOINTMENT_SAGA_FAIL =
  'app/Appointment/RESERVE_APPOINTMENT_SAGA_FAIL';

export const APPLY_REFERRAL = 'APPLY_REFERRAL';
export const APPLY_REFERRAL_SUCCESS = 'APPLY_REFERRAL_SUCCESS';

export const FETCH_NEAREST_LOCATION = 'app/Appointment/FETCH_NEAREST_LOCATION';
export const FETCH_NEAREST_LOCATION_SUCCESS =
  'app/Appointment/FETCH_NEAREST_LOCATION_SUCCESS';
export const FETCH_NEAREST_LOCATION_FAILURE =
  'app/Appointment/FETCH_NEAREST_LOCATION_FAILURE';

export const CLEAR_NEAREST_LOCATIONS =
  'app/Appointment/CLEAR_NEAREST_LOCATIONS';

export const GET_LIABILITY_WAIVER = 'app/Appointment/GET_LIABILITY_WAIVER';
export const GET_LIABILITY_WAIVER_SUCCESS =
  'app/Appointment/GET_LIABILITY_WAIVER_SUCCESS';
export const GET_LIABILITY_WAIVER_FAILURE =
  'app/Appointment/GET_LIABILITY_WAIVER_FAILURE';

export const SEND_SIGNATURE = 'app/Appointment/SEND_SIGNATURE';
export const SEND_SIGNATURE_SUCCESS = 'app/Appointment/SEND_SIGNATURE_SUCCESS';
export const SEND_SIGNATURE_FAILURE = 'app/Appointment/SEND_SIGNATURE_FAILURE';

export const SUGGESTED_TIP_AMOUNT = 'app/Appointment/SUGGESTED_TIP_AMOUNT';
export const SUGGESTED_TIP_AMOUNT_SUCCESS =
  'app/Appointment/SUGGESTED_TIP_AMOUNT_SUCCESS';
export const SUGGESTED_TIP_AMOUNT_FAILURE =
  'app/Appointment/SUGGESTED_TIP_AMOUNT_FAILURE';

export const GET_IP_ADDRESS = 'app/Appointment/GET_IP_ADDRESS';
export const GET_IP_ADDRESS_SUCCESS = 'app/Appointment/GET_IP_ADDRESS_SUCCESS';
export const GET_IP_ADDRESS_FAILURE = 'app/Appointment/GET_IP_ADDRESS_FAILURE';

export const GEOLOCATION_API_STATUS = 'app/Appointment/GEOLOCATION_API_STATUS';
